













































import { Component, Vue } from 'vue-property-decorator'
import { apiGoodsTop } from '@/api/data/data'

@Component
export default class User extends Vue {
    /** S Data **/
    goodsTopData = []

    /** E Data **/

    /** S Methods **/

    onReset() {
        this.getDataCenterVisit()
    }

    // 获取数据
    getDataCenterVisit() {
        apiGoodsTop({}).then(res => {
            this.goodsTopData = res
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getDataCenterVisit()
    }
    /** E Life Cycle **/
}
